import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import CareersAccordian from '@components/CareersAccordian'
import CareersGraph from '@components/CareersGraph'
import CareersImages from '@components/CareersImages'
import Perks from '@components/Perks'
import Section from '@components/Section'
import media from '@styles/media'

import CareersRow from './Careers.Row'

// Grabbing all the gallery images. Returns an Array[] of images
const galleryQuery = graphql`
  query CareersBodyQuery {
    gallery: allFile(filter: { name: { regex: "/careers-gallery/" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 960, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`

const galleryDescriptions = [
  ' team members looking at new tech products',
  'Mack telling a funny story at a team dinner',
  ' directing an onsite photoshoot',
  'Soya, our beloved cat',
  ' team working together at a Cafe',
  ' team out for dinner',
  ' working on the assets for Hopper.com',
  ' team visits the Hopper office',
]

/**
 * Careers Body
 * - Why Muse Zade
 * - Working at Muse Zade
 * - Rail/Horizontal Scroll of Gallery images
 * - Building our future (Labs/Studio)
 * - Careers
 */
function CareersBody() {
  const { gallery } = useStaticQuery(galleryQuery)

  return (
    <>
      <CareersRow header="Why Muse Zade">
        <SectionCopy maxWidth="69rem">
          MuseZade- a bossless, project-oriented company. We plan, we build we deliver, all as a team.
        </SectionCopy>
      </CareersRow>
      <CareersRow header="Working at MuseZade">
        <FlexColumn>
          <WhatWeDoContent>
            <SectionCopy maxWidth="42rem">
              Get a chance to choose how and when you want to work with us.
            </SectionCopy>
          </WhatWeDoContent>
          <WhatWeDoList>
            <Perks />
          </WhatWeDoList>
        </FlexColumn>
      </CareersRow>

      {/* Horizontal scroll Gallery */}
      <CareersRow header="We are flexible">
        <SectionCopy maxWidth="67rem">
          You can choose to be fully remote as well
        </SectionCopy>
        
      </CareersRow>

      {/* Graph with Studio and Labs */}
      <CareersRow
        header={
          <div style={{ paddingRight: '2.5rem' }}>Building our future</div>
        }
      >
        <SectionCopy maxWidth="67rem">
         We have clients from all around the globe.
          <LabsLink to="/labs">MuseZade Workfolio</LabsLink>.
        </SectionCopy>
      </CareersRow>

      {/* Get a job at Muse Zade */}
      <CareersRow header="Say hello">
        <SectionCopy maxWidth="67rem">
          If you have the devotion, the curiosity and the desire to build great
          things, you might fit right in.
        </SectionCopy>
      </CareersRow>
      <Section narrow>
        <CareersAccordian />
      </Section>
    </>
  )
}

export default CareersBody

const SectionCopy = styled.p`
  color: #fff;
  font-size: 3.2rem;
  line-height: 1.2;
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};

  ${media.desktop`
    font-size: 2.2rem;
    line-height: 1.4;
    max-width: 100%;
  `};

  ${media.tablet`
    font-size: 1.8rem;
  `};
`

const WhatWeDoList = styled.div`
  width: 38rem;
  list-style: none;

  ${media.desktop`
    width: 100%;
  `};
`

const FlexColumn = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.desktop`
    flex-direction: column;
  `};
`

const WhatWeDoContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.desktop`
    margin-bottom: 2.5rem;
  `};
`

const LabsLink = styled(Link)`
  text-decoration: underline;
  text-decoration-color: ${p => p.theme.colors.gold};
  color: #fff;
  transition: color 0.3s ease-out;

  &:hover {
    color: ${p => p.theme.colors.gold};
  }
`
